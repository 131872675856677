import React, { useContext } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useTheme } from "@material-ui/core/styles";

import TopBar from "./../layout/topBar/TopBar";
import CustomDrawer from "./../layout/CustomDrawer";
import { AuthContext } from "./../contexts/AuthContext";
import { makeStyles } from "@material-ui/styles";
import { Outlet } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#20273d",
    color: "white",
  },
  content: {
    width: "100%",
    // flexGrow: 1,
    // padding: theme.spacing(3),
  },
}));

function FullLayout(props: any): any {
  const { window } = props;
  const classes: any = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const authContext: any = useContext(AuthContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const isLogged = authContext.isLogged;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        {isLogged && <TopBar handleDrawerToggle={handleDrawerToggle} />}
        {isLogged && (
          <CustomDrawer
            classes={classes}
            container={container}
            theme={theme}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            closeDrawer={closeDrawer}
          />
        )}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default FullLayout;
