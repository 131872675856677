export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT_ACTION = "LOGOUT_ACTION";
export const CLOSE_ALERT = "CLOSE_ALERT";
export const TRIGGER_ALERT = "TRIGGER_ALERT";
export const SET_TOKEN_DATA = "SET_TOKEN_DATA";

export const setToken = (token: any) => {
  // TODO : Uncomment when needed
  // Utils.setCookies("token", token);
  return {
    type: SET_TOKEN,
    token,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT_ACTION,
  };
};

export const closeAlert = () => {
  return {
    type: CLOSE_ALERT,
  };
};

export const setAlert = (alert: any) => {
  return {
    type: TRIGGER_ALERT,
    alert,
  };
};
