import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { deleteCookie } from "../Utils";
import { useNavigate } from "react-router-dom";
import { setToken } from "../redux/actions";

const AuthContext = createContext(null);

export { AuthContext };
const Auth = ({ children }: any): any => {
  const [context, setContext] = useState(localStorage.getItem("context"));
  const [data, setData] = useState<any>(null);
  const token = useSelector((state: any) => state.token);
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(Boolean(token));
  const dispatch = useDispatch();

  useEffect(() => {
    handleNewToken(token);
    setIsLogged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleNewToken = (token: string) => {
    // TODO : Uncomment when needed
    // setAxiosAuthHeaderToken(token);
    // Utils.setCookies("token", token);
    try {
      const decoded: any = jwt_decode(token);
      setData(decoded);
      if (!context) {
        setContext(decoded.context);
      }
    } catch (error: any) {
      setData({});
    }
  };

  const logout = () => {
    localStorage.clear();
    deleteCookie();
    // setAxiosAuthHeaderToken("");
    setIsLogged(false);
    dispatch(setToken(null));
    navigate("/login");
  };

  const value: any = {
    data,
    context,
    logout,
    isLogged,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default Auth;
