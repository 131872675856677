import { Hidden, Drawer } from "@material-ui/core";
import React from "react";
import SideBar from "./SideBar";

const CustomDrawer: React.FC<any> = ({
  classes,
  container,
  theme,
  mobileOpen,
  handleDrawerToggle,
  closeDrawer,
}: any) => {
  return (
    <nav className={classes.drawer} aria-label="naviagtion">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <SideBar close={closeDrawer} cls={classes} />
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <SideBar close={closeDrawer} cls={classes} />
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default CustomDrawer;
