import { Navigate, useRoutes } from "react-router-dom";
import { lazy, Suspense } from "react";
import LogoLoader from "../components/LogoLoader";
import FullLayout from "../layout/FullLayout";
import NoMenuLayout from "../layout/NoMenuLayout";
const NotFound = lazy(() => import("../pages/NotFound"));
const Home = lazy(() => import("../pages/Home"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const Login = lazy(() => import("../pages/Login"));
const MyLinks = lazy(() => import("../pages/MyLinks"));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/app",
      element: <FullLayout />,
      children: [
        { path: "", element: <Navigate to="/app/home" replace /> },
        {
          path: "/home",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: "/profile/:tab",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfilePage />
            </Suspense>
          ),
        },
        {
          path: "/my-links",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <MyLinks />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/",
      element: <NoMenuLayout />,
      children: [
        {
          path: "login",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <Login />
            </Suspense>
          ),
        },
        {
          path: "404",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <NotFound />
            </Suspense>
          ),
        },
        { path: "/", element: <Navigate to="/app" replace /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
