import { deleteCookie } from "../Utils";
import {
  SET_TOKEN,
  LOGOUT_ACTION,
  TRIGGER_ALERT,
  CLOSE_ALERT,
} from "./actions";

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case LOGOUT_ACTION:
      deleteCookie();
      localStorage.clear();
      return {
        ...state,
        token: null,
      };
    case TRIGGER_ALERT:
      return {
        ...state,
        openAlert: true,
        alert: action.alert,
      };
    case CLOSE_ALERT:
      return {
        ...state,
        openAlert: false,
      };
    default:
      return { ...state };
  }
};

export default reducer;
