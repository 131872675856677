import { Box } from "@material-ui/core";
import React from "react";

// import logo from "/static/logo.svg";
import CollabContextMenu from "../contextMenus/collaborators/CollabContextMenu";

const SideBar: React.FC<any> = ({ cls, close }: any) => {
  const handleLinkClick = () => {
    close();
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/static/logo.svg"
          alt="logo entreprise"
          style={{ width: "200px" }}
        />
      </Box>
      {/* <div className={cls.toolbar} /> */}
      {/* <Divider /> */}
      {/* <CollabContextMenu handleLinkClick={handleLinkClick} /> */}
    </>
  );
};

export default SideBar;
