import { Backdrop, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";

const LogoLoader: React.FC<{
  open: boolean;
  background?: boolean;
}> = ({ open, background = false }) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        ...(background ? {} : { background: "none" }),
        marginTop: theme.spacing(8),
        [theme.breakpoints.up("sm")]: {
          marginLeft: 240,
        },
        [theme.breakpoints.down("md")]: {
          marginTop: theme.spacing(7),
        },
      },
    })
  );

  const classes: any = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <img className="App-logo" src="/static/logo.svg" alt="logo" />
    </Backdrop>
  );
};
export default LogoLoader;
