import React from "react";
import "./App.scss";
// import { AuthContext } from "./contexts/AuthContext";
import DefaultRoutes from "./routes/routes";

const App: React.FC = (props: any) => {
  // const authContext: any = useContext(AuthContext);

  // const isLogged = authContext.isLogged;

  const getContextRouting = () => {
    return <DefaultRoutes />;
  };

  return <>{getContextRouting()}</>;
};

export default App;
