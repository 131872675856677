export const Utils = {
  setCookies: (item: string, value: string) => {
    const now = new Date();
    let time = now.getTime();
    // Expire au bout de 2h
    time += 3600 * 2000;
    now.setTime(time);
    document.cookie =
      `${item}=` + value + "; path=/; expires=" + now.toUTCString();
  },
  getCookie: (cname: string) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let c of ca) {
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
};

export const deleteCookie = (): void => {
  document.cookie = "token=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
};

export const compareChanges = (options: any, params: any): boolean => {
  if (!params) return true;
  const keys = Object.keys(params);
  const obj: any = {};

  keys.forEach((key: string) => {
    const currentValue = options.filter((el: any) => el.key === key)[0]
      ?.defaultValue;
    obj[key] = currentValue;
  });

  const optKeys = options.map((o: any) => o.key);
  const formattedOpt: any = {};
  optKeys.forEach((key: string) => {
    formattedOpt[key] = options.filter(
      (el: any) => el.key === key
    )[0]?.defaultValue;
  });

  const comp = { ...obj, ...formattedOpt };

  return JSON.stringify(comp) !== JSON.stringify(params);
};

export const formatSkillsArray = (skills: any) => {
  console.log("format", skills);
  const skillsCategories = skills.map((skill: any) => skill.skillCategory);
  console.log("Categories", skillsCategories);
};

export const sortCategoryAlphabetic = (order = "asc") => {
  return (a: any, b: any) => {
    const varA = a.label;
    const varB = b.label;

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const sortSkills = (skills: any) => {
  const skillCategory: any = [];
  const finalSkills: any = [];

  // eslint-disable-next-line array-callback-return
  skills?.map((skill: any) => {
    if (!skill?.skillCategory) {
      skill.skillCategory = { id: "none" };
    }

    if (skillCategory.indexOf(skill?.skillCategory?.id) === -1) {
      const category = {
        id: skill?.skillCategory?.id || "none",
        label: skill?.skillCategory?.label || "Sans catégorie",
        description: skill?.skillCategory?.description,
        skillsList: [],
      };
      skillCategory.push(category);
    }
    // eslint-disable-next-line array-callback-return
    skillCategory.map((item: any) => {
      if (item.id === skill?.skillCategory?.id) {
        item.skillsList.push(skill);
      }
    });
  });

  const final = skillCategory.filter(function (elem: any) {
    if (finalSkills.indexOf(elem.id) === -1) {
      finalSkills.push(elem.id);
      return true;
    } else {
      return false;
    }
  });
  return final;
};

export const convertBase64ToFile = (image: any) => {
  const byteString = atob(image.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: "image/jpeg",
  });
  return newBlob;
};

export const addToStateArray = (array: any, element: any) => {
  return [...array, element];
};
