import axios from "axios";
import { baseURL } from "./config";
import { deleteCookie } from "./Utils";

export const setAxios = () => {
  /** axios configuration */
  axios.defaults.baseURL = baseURL;
  // TODO : Uncomment when needed
  // const cookieToken = Utils.getCookie("token");
  // setAxiosAuthHeaderToken(cookieToken);
  // axios.defaults.headers["Accept"] = "application/ld+json";
  // axios.defaults.headers["Content-Type"] = "application/ld+json";
  // axios.defaults.headers["content-language"] =
  //   localStorage.getItem("lang") || "fr";

  axios.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error) {
      // response error

      if (error?.response?.status === 401) {
        localStorage.clear();
        deleteCookie();
        /**
         * Waiting for react-router v6 to fix the issue where we can't use it outside a react component
         */
        sessionStorage.setItem("disconnected", "401");
        window.location.href = "/login";
      }
      // console.log("Intercepted error", error);
      return Promise.reject(error?.response ?? error); // transform response.response -> response
    }
  );
};

export const setAxiosAuthHeaderToken = (token: string) => {
  axios.defaults.headers["Authorization"] = `Bearer ${token}`;
};
